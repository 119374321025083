import React from "react";

import "./MadeByCut.css";

import imgCut from "./cut_black.jpg";

const Cut = () => {
  return (
    <div className="cut">
      <span>made by</span>
      <img alt="cut" src={imgCut} />
    </div>
  );
};

export default Cut;
