import React, { useEffect, useReducer, useState } from "react";
import { useParams } from "react-router-dom";

import Box from "components/Box";
import Button from "components/Button";
import Icon from "components/Icon";
import Card from "shared/Card";
import Menu from "./Menu";
import PlayerSelect from "./PlayerSelect";
import useDecks from "Decks.js";

import "./Play.css";

const shuffle = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }

  return array;
};

const derivePrompt = ({ card, hosts }) => {
  if (card.type === "round-on-me") return "Who answered best?";

  return [
    "Who will answer question A?",
    "Who will answer question B?",
    "Who answered better?",
  ][hosts.length];
};

const byPoints = (a, b) => b.points - a.points;

const isGameOver = ({ players }) => {
  const [leader] = players.slice().sort(byPoints);

  if (players.length <= 4 && leader.points === 5) return leader;
  if (players.length <= 7 && leader.points === 4) return leader;
  if (players.length >= 8 && leader.points === 3) return leader;
  return false;
};

const initialState = (players) => ({
  cards: [],
  dealer: 0,
  hosts: [],
  players,
  round: 0,
  score: {},
});

function reducer(state, action) {
  switch (action.type) {
    case "cards":
      return { ...state, cards: action.cards };
    case "score": {
      const { player } = action;
      const dealer = (state.dealer + 1) % state.players.length;
      const recipient = { ...player, points: player.points + 1 };
      const players = state.players.map((p) =>
        p.id === recipient.id ? recipient : p
      );
      const round = (state.round + 1) % state.cards.length;
      return { ...state, dealer, hosts: [], players, round };
    }
    case "restart": {
      const cards = shuffle(state.cards.slice());
      const players = state.players.map((p) => ({ ...p, points: 0 }));
      return { ...state, cards, dealer: 0, hosts: [], players, round: 0 };
    }
    case "host":
      return { ...state, hosts: [...state.hosts, action.player] };
    case "leaver": {
      const leaverIndex = state.players.indexOf(action.leaver);
      const dealer =
        state.dealer <= leaverIndex ? state.dealer : state.dealer - 1;
      const players = state.players.filter((p) => p.id !== action.leaver.id);
      return {
        ...state,
        dealer: dealer % players.length,
        players,
      };
    }
    case "joiner":
      return { ...state, players: state.players.concat([action.joiner]) };
    case "skip":
      const round = (state.round + 1) % state.cards.length;
      return { ...state, round };
    default:
      break;
  }
}

const Play = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState(props.players));
  const { cards, hosts, round, players } = state;
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const decks = useDecks();
  const { deck: deckId } = useParams();

  const deck = decks[deckId];

  useEffect(() => {
    const cards = shuffle(deck.cards.slice());

    dispatch({ type: "cards", cards });
  }, [deck.cards]);

  const onScore = (player) => {
    dispatch({ type: "score", player });
  };

  const onJoin = (joiner) => dispatch({ type: "joiner", joiner });
  const onLeave = (leaver) => dispatch({ type: "leaver", leaver });

  const handleSkip = () => dispatch({ type: "skip" });

  const handleRestart = () => {
    dispatch({ type: "restart" });
  };

  const setHost = (player) => dispatch({ type: "host", player });

  if (cards.length === 0) return null;

  const card = cards[round];

  const isRoundOnMe = card.type === "round-on-me";

  const dealer = players[state.dealer];
  const restricted = [dealer, ...hosts];
  const choices = players.filter((p) => !restricted.includes(p));

  const prompt = derivePrompt({ card, hosts });

  const winner = isGameOver({ players });
  if (winner) return <GameOver onRestart={handleRestart} winner={winner} />;

  return (
    <div id="game">
      <Box flex justifyContent="space-between">
        <Card card={card} deck={deck} />
        <Box className="sidebar">
          <Box className="dealer">
            <p className="dealer__label">Dealer:</p>
            <PlayerSelect limit={6} players={[dealer]} vertical />
          </Box>
          <Box className="actions">
            <Button onClick={handleSkip} round>
              <Icon icon="skipNext" white />
            </Button>
            <Button onClick={() => setIsMenuOpen(true)} round>
              <Icon icon="menu" white />
            </Button>
          </Box>
        </Box>
      </Box>
      <Box mt="1.5rem">
        <p className="prompt">{prompt}</p>
        {isRoundOnMe ? (
          <PlayerSelect onSelect={onScore} players={choices} />
        ) : hosts.length < 2 ? (
          <PlayerSelect onSelect={setHost} players={choices} />
        ) : (
          <PlayerSelect onSelect={onScore} players={hosts} />
        )}
      </Box>
      <Menu
        isOpen={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        onJoin={onJoin}
        onLeave={onLeave}
        players={players}
      />
    </div>
  );
};

const GameOver = ({ onRestart, winner }) => {
  return (
    <div id="game-over">
      <Box id="end-screen">
        <p>{winner.name} wins!</p>
        <Button stretch onClick={onRestart}>
          Play Again
        </Button>
        <Button link="/" secondary>
          Choose new deck
        </Button>
      </Box>
    </div>
  );
};

export default Play;
