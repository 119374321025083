import React from "react";

import "./Text.css";

const Text = (props) => {
  const { caps, center, children } = props;

  let classname = "text";
  classname = classname + (center ? " text--center" : "");
  classname = classname + (caps ? " text--caps" : "");

  return <p className={classname}>{children}</p>;
};

export default Text;
