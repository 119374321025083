import React from "react";

import "./PlayerSelect.css";

const PlayerSelect = ({ limit, onSelect, players, select, vertical }) => {
  let classname = "player-select";
  classname = classname + (vertical ? " player-select--vertical" : "");

  const selectIndex = players.findIndex((p) => p === select);
  const splitIndex = Math.max(selectIndex, 0);

  const head = players.slice(splitIndex);
  const tail = players.slice(0, splitIndex);
  const ordered = [...head, ...tail].slice(0, limit || players.length);

  return (
    <ul className={classname}>
      {ordered.map((player) => (
        <Player
          key={player.id}
          onClick={onSelect && (() => onSelect(player))}
          selected={select && select === player}
          player={player}
        />
      ))}
    </ul>
  );
};

const Player = ({ onClick, player, selected }) => {
  let classname = "player-option";
  classname = classname + (selected ? " player-option--selected" : "");

  const name = player.name.slice(0, 1);

  return (
    <li className={classname}>
      <button onClick={onClick && (() => onClick())} title={player.name}>
        {player.photo ? <img alt={player.name} src={player.photo} /> : name}
      </button>
    </li>
  );
};

export default PlayerSelect;
