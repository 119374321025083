import React, { useState } from "react";

import Header from "shared/Header";
import Page from "components/Page";
import Play from "./Play";
import Players from "./Players";

const Game = () => {
  const [players, setPlayers] = useState([]);

  return (
    <Page>
      <Header />
      {players.length === 0 ? (
        <Players onSave={setPlayers} />
      ) : (
        <Play players={players} />
      )}
    </Page>
  );
};

export default Game;
