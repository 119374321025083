import React, { useContext } from "react";

import extradirty from "data/decks/extradirty/extradirty.json";
import happyhour from "data/decks/happyhour/happyhour.json";
import lastcall from "data/decks/lastcall/lastcall.json";
import ontherocks from "data/decks/ontherocks/ontherocks.json";

const DeckContext = React.createContext([]);

const DeckProvider = ({ children }) => {
  const decks = {
    extradirty,
    happyhour,
    lastcall,
    ontherocks,
  };

  return <DeckContext.Provider value={decks}>{children}</DeckContext.Provider>;
};

const useDecks = () => useContext(DeckContext);

export default useDecks;
export { DeckProvider };
