import React from "react";

import "./TruthOrDrink.css";

import imgTitle from "./truthordrink.png";

const TruthOrDrink = () => {
  return (
    <h1 className="truthordrink">
      <img alt="Truth or Drink" src={imgTitle} />
    </h1>
  );
};

export default TruthOrDrink;
