import React from "react";

import Box from "components/Box";
import TruthOrDrink from "shared/TruthOrDrink";

import "./Card.css";

const Card = ({ card, deck, onClick }) => {
  const { theme } = deck;

  let classname = "playcard";
  classname = classname + (onClick ? " playcard--clickable" : "");

  return (
    <div className={classname} onClick={onClick ? () => onClick() : null}>
      <Box
        background={theme.background}
        className="playcard__deck"
        color={theme.foreground}
      >
        {deck.name}
      </Box>
      <div className="playcard__body">
        {card.questions.map((question, i) => (
          <Prompt key={i} i={i} type={card.type} question={question} />
        ))}
        <Hint type={card.type} />
      </div>
      <div className="playcard__footer">
        <TruthOrDrink />
      </div>
    </div>
  );
};

const getPrompt = ({ i, type }) => {
  if (type === "round-on-me") return "This round's on me.";

  return "ab"[i];
};

const Prompt = ({ i, type, question }) => {
  return (
    <div className="playcard__prompt">
      <div className="playcard__title">{getPrompt({ i, type })}</div>
      <div className="playcard__question">{question}</div>
    </div>
  );
};

const Hint = ({ type }) => {
  if (type !== "round-on-me") return null;

  return <span className="playcard__hint">Everybody else answers.</span>;
};

export default Card;
