import React from "react";
import { Link } from "react-router-dom";

import TruthOrDrink from "shared/TruthOrDrink";
import MadeByCut from "shared/MadeByCut";

import "./Header.css";

const Header = () => {
  return (
    <header className="header">
      <Link to="/">
        <TruthOrDrink />
      </Link>
      <MadeByCut />
    </header>
  );
};

export default Header;
