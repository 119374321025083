import React, { useState } from "react";
import { useParams } from "react-router-dom";

import Box from "components/Box";
import Card from "shared/Card";
import Header from "shared/Header";
import Page from "components/Page";
import useDecks from "Decks.js";

const Deck = () => {
  const [round, setRound] = useState(0);

  const decks = useDecks();
  const { deck: deckId } = useParams();

  const deck = decks[deckId];
  const cards = deck.cards;
  const card = cards[round];

  const handleClick = () => setRound((round + 1) % cards.length);

  return (
    <Page>
      <Header />
      <Box flex justifyContent="center">
        <Card card={card} deck={deck} onClick={handleClick} />
      </Box>
    </Page>
  );
};

export default Deck;
