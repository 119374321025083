import React from "react";

import Deck from "shared/Deck";
import Header from "shared/Header";
import Text from "components/Text";
import Page from "components/Page";
import useDecks from "Decks.js";

import "./Decks.css";

const Decks = () => {
  const decks = useDecks();

  return (
    <Page>
      <Header />
      <Text center>Choose a deck to get started.</Text>
      <ul className="decks">
        {Object.values(decks).map((deck) => (
          <Deck key={deck.id} deck={deck} />
        ))}
      </ul>
    </Page>
  );
};

export default Decks;
