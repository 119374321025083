import React, { useRef, useState } from "react";

import Box from "components/Box";
import Button from "components/Button";
import Form, { Field } from "components/Form";
import Icon from "components/Icon";
import Player from "Game/Player";
import Preview from "./Preview";

import "./Players.css";

const capturePhoto = (videoEl) =>
  new Promise((resolve) => {
    const height = videoEl.current.videoHeight;
    const width = videoEl.current.videoWidth;

    const canvasEl = document.createElement("canvas");
    canvasEl.height = height;
    canvasEl.width = width;

    var context = canvasEl.getContext("2d");

    context.drawImage(videoEl.current, 0, 0, width, height);

    canvasEl.toBlob((blob) => resolve(URL.createObjectURL(blob)));
  });

const initialPlayers = [
  Player.init("Player 1"),
  Player.init("Player 2"),
  Player.init("Player 3"),
];

const Players = (props) => {
  const { onSave } = props;

  const previewEl = useRef(null);
  const [preview, setPreview] = useState(null);
  const [players, setPlayers] = useState(initialPlayers);

  const updatePlayer = (e, i) =>
    setPlayers(
      players.map((p, j) => (i === j ? Player.name(p, e.target.value) : p))
    );

  const newPlayer = (e) => {
    e.preventDefault();
    setPlayers([...players, Player.init(`Player ${players.length + 1}`)]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    onSave(players);
  };

  const handleSelfieClick = (i) => {
    const player = players[i];

    if (preview === null) return setPreview(player);

    (async () => {
      const photo = await capturePhoto(previewEl);

      setPlayers(players.map((p, j) => (i === j ? Player.photo(p, photo) : p)));
    })();

    setPreview(null);
  };

  return (
    <Form className="players" onSubmit={handleSubmit}>
      <Box mb="1rem">
        <Preview ref={previewEl} show={preview !== null} />
      </Box>
      <h1>Who's playing?</h1>
      <p>Add up to 16 players</p>
      {players.map((player, i) => (
        <Box flex mt="1rem" key={i}>
          <Field
            key={i}
            onChange={(val) => updatePlayer(val, i)}
            placeholder={`Player ${i + 1}`}
            value={player.name}
          />
          <div className="selfie" onClick={() => handleSelfieClick(i)}>
            {player.photo ? (
              <img alt="" src={player.photo} />
            ) : (
              <Icon icon="camera" />
            )}
          </div>
        </Box>
      ))}
      {players.length < 16 && (
        <button id="new-player" onClick={newPlayer}>
          + Add Player
        </button>
      )}
      <Box mt="2rem">
        <Button stretch>Play</Button>
      </Box>
    </Form>
  );
};

export default Players;
