import React from "react";

import "./Form.css";

const Form = ({ className, ...props }) => {
  className = "form " + className;

  return (
    <form className={className} {...props}>
      {props.children}
    </form>
  );
};

const FieldSet = ({ children }) => {
  return <fieldset className="field-set">{children}</fieldset>;
};

const Field = (props) => {
  return <input {...props} className="field" />;
};

const Radio = (props) => {
  const { checked, children, onClick, name } = props;

  let classname = "radio";
  classname = classname + (checked ? " radio--checked" : "");

  return (
    <label className={classname} onClick={() => onClick()}>
      <input
        checked={checked}
        className="radio__input"
        onChange={() => onClick()}
        type="radio"
        name={name}
      />
      <span className="radio__button" />
      {children}
    </label>
  );
};

const TextArea = React.forwardRef((props, ref) => {
  const { expand, maxLength, onChange, onSubmit, ...rest } = props;

  const handleChange = (event) => onChange(event.target.value);

  const handleKeyDown = (event) => {
    const isEnter = event.key === "Enter";
    const isModified = event.ctrlKey || event.metaKey;

    if (isEnter && isModified) onSubmit();
  };

  let classname = "textarea";
  classname = classname + (expand ? " textarea--expand" : "");

  return (
    <textarea
      className={classname}
      maxLength={maxLength}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      ref={ref}
      {...rest}
    />
  );
});

export default Form;
export { FieldSet, Field, Radio, TextArea };
