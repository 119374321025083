import React from "react";

import "./Icon.css";

import iconLoading from "./icons/loading.png";
import iconLoadingWhite from "./icons/loading-white.png";
import { ReactComponent as AddIcon } from "./icons/add.svg";
import { ReactComponent as AnnounceIcon } from "./icons/announce.svg";
import { ReactComponent as CameraIcon } from "./icons/camera.svg";
import { ReactComponent as CheckmarkIcon } from "./icons/checkmark.svg";
import { ReactComponent as CloseIcon } from "./icons/close.svg";
import { ReactComponent as DownloadIcon } from "./icons/download.svg";
import { ReactComponent as ErrorIcon } from "./icons/error.svg";
import { ReactComponent as ExpandLessIcon } from "./icons/expand-less.svg";
import { ReactComponent as ExpandMoreIcon } from "./icons/expand-more.svg";
import { ReactComponent as InfoIcon } from "./icons/info.svg";
import { ReactComponent as LockIcon } from "./icons/lock.svg";
import { ReactComponent as MenuIcon } from "./icons/menu.svg";
import { ReactComponent as NotifyIcon } from "./icons/notify.svg";
import { ReactComponent as PauseIcon } from "./icons/pause.svg";
import { ReactComponent as PlayIcon } from "./icons/play.svg";
import { ReactComponent as RemoveIcon } from "./icons/remove.svg";
import { ReactComponent as StopIcon } from "./icons/stop.svg";
import { ReactComponent as ScoreboardIcon } from "./icons/scoreboard.svg";
import { ReactComponent as SkipNextIcon } from "./icons/skip-next.svg";
import { ReactComponent as SuccessIcon } from "./icons/success.svg";
import { ReactComponent as WarningIcon } from "./icons/warning.svg";

const LoadingIcon = () => <img alt="loading" src={iconLoading} />;
const LoadingIconWhite = () => <img alt="loading" src={iconLoadingWhite} />;

const icons = {
  add: AddIcon,
  announce: AnnounceIcon,
  camera: CameraIcon,
  checkmark: CheckmarkIcon,
  close: CloseIcon,
  download: DownloadIcon,
  error: ErrorIcon,
  expandLess: ExpandLessIcon,
  expandMore: ExpandMoreIcon,
  info: InfoIcon,
  loading: LoadingIcon,
  loadingWhite: LoadingIconWhite,
  lock: LockIcon,
  notify: NotifyIcon,
  menu: MenuIcon,
  pause: PauseIcon,
  play: PlayIcon,
  remove: RemoveIcon,
  stop: StopIcon,
  scoreboard: ScoreboardIcon,
  skipNext: SkipNextIcon,
  success: SuccessIcon,
  warning: WarningIcon,
};

const Icon = (props) => {
  const { className, dark, icon, onClick, spin, white } = props;

  const Image = icons[icon];

  const Container = onClick ? "button" : "span";

  let classname = "icon";
  classname = classname + (dark ? " icon--dark" : "");
  classname = classname + (spin ? " icon--spin " : "");
  classname = classname + (white ? " icon--white" : "");
  classname = classname + (className ? ` ${className}` : "");

  return (
    <Container className={classname} onClick={onClick}>
      <Image />
    </Container>
  );
};

export default Icon;
