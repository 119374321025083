import React from "react";
import { DialogContent, DialogOverlay } from "@reach/dialog";

import Box from "components/Box";
import Button from "components/Button";
import Icon from "components/Icon";
import Form, { Field } from "components/Form";
import Player from "Game/Player";

import "./Menu.css";

export default function Menu({ isOpen, onClose, onLeave, onJoin, players }) {
  return (
    <DialogOverlay onDismiss={onClose} isOpen={isOpen}>
      <DialogContent className="dialog">
        <ul className="board">
          <li className="board__row board__row--header">
            <div className="board__delete"></div>
            <span className="board__name">Player</span>
            <span>Points</span>
          </li>
          {players.map((player) => (
            <li className="board__row" key={player.id}>
              <div className="board__delete">
                <Button
                  disabled={players.length <= 3}
                  onClick={() => onLeave(player)}
                  secondary
                >
                  <Icon icon="remove" />
                </Button>
              </div>
              <span className="board__name">{player.name}</span>
              <span>{player.points}</span>
            </li>
          ))}
        </ul>
        <NewPlayer onJoin={onJoin} />
      </DialogContent>
    </DialogOverlay>
  );
}

function NewPlayer({ onJoin }) {
  const [name, setName] = React.useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    const player = Player.init(name);
    onJoin(player);
    setName("");
  };

  return (
    <Form className="newcomer" onSubmit={handleSubmit}>
      <Box>
        <Button>
          <Icon icon="add" white />
        </Button>
      </Box>
      <Box w="100%">
        <label for="newcomer">New Player:</label>
        <Field
          id="newcomer"
          onChange={(e) => setName(e.target.value)}
          value={name}
        />
      </Box>
    </Form>
  );
}
