import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Deck from "Deck";
import Game from "Game";
import Decks from "Decks/Decks";
import { DeckProvider } from "Decks.js";

const Middleware = () => {
  return (
    <BrowserRouter>
      <DeckProvider>
        <App />
      </DeckProvider>
    </BrowserRouter>
  );
};

const App = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Decks />
      </Route>
      <Route path="/decks/:deck">
        <Deck />
      </Route>
      <Route path="/game/:deck">
        <Game />
      </Route>
    </Switch>
  );
};

export default Middleware;
