import React, { useState } from "react";

import Box from "components/Box";
import Button from "components/Button";

import "./Deck.css";

import imgCardBack from "./card-back.png";

const Deck = ({ deck }) => {
  const { id, description, theme, name } = deck;

  const [showInfo, setShowInfo] = useState(false);

  const className = `deck deck--${id}`;

  return (
    <li className={className}>
      <div className="deck__body" onClick={() => setShowInfo(!showInfo)}>
        <Name name={name} theme={theme} />
        <Info description={description} id={id} name={name} show={showInfo} />
      </div>
    </li>
  );
};

const Name = ({ theme, name }) => (
  <>
    <img alt="" src={imgCardBack} />
    <Box
      background={theme.background}
      className="deck__name"
      color={theme.foreground}
    >
      {name}
    </Box>
  </>
);

const Info = ({ description, id, name, show }) => {
  if (!show) return null;

  return (
    <div className="info">
      <div>
        <div className="info__title">{name}:</div>
        <p className="info__text">{description}</p>
      </div>
      <div>
        <Button link={`/game/${id}`} stretch>
          Play
        </Button>
        <Box mt="1rem">
          <Button link={`/decks/${id}`} secondary>
            Show deck
          </Button>
        </Box>
      </div>
    </div>
  );
};

export default Deck;
