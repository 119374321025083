import React, { useEffect } from "react";

import "./Preview.css";

const Preview = React.forwardRef(({ show }, ref) => {
  ref = ref ?? React.createRef();

  useEffect(() => {
    if (!show) return;

    let videoStream;

    (async () => {
      videoStream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: "user" },
      });

      ref.current.srcObject = videoStream;
      ref.current.play();
    })();

    return () => videoStream.getTracks().forEach((t) => t.stop());
  }, [ref, show]);

  if (!show) return null;

  return (
    <div className="preview">
      <video ref={ref} />
    </div>
  );
});

export default Preview;
